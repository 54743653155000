import { SystemProps, Flex, Grid, css, Box } from '@storyofams/react-ui'
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit'
import { Text, Button, Heading, FixedRatio } from '~components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { SectionWrapper } from '~components/common/SectionWrapper'

type GridProductsHomeV2Props = {
  content: {
    title?: string
    subtitle?: string
    items?: Array<{
      _uid?: string
      image?: {
        filename?: string
        alt?: string
      }
      title?: string
      title_align?: any
      description?: string
      description_icon?: {
        filename?: string
        alt?: string
      }
      description_align?: any
      button_link?: {
        cached_url?: string
      }
      background_color?: {
        color?: string
      }
      font_color?: {
        color?: string
      }
    }>
    background_color?: {
      color?: string
    }
    font_color?: {
      color?: string
    }
    slider_arrow_color?: {
      color?: string
    }
    use_carousel_mobile: boolean
    stack_mobile: boolean
  }
  first?: boolean
} & SystemProps

const BlockItem = ({ item, carousel }) => {
  const customButtonProps =
    item?.use_custom_button_color &&
    !!item?.button_color?.color &&
    !!item?.button_shadow_color?.color
      ? {
          customBackgroudColor: item?.button_color?.color,
          customBackgroudShadowColor: item?.button_shadow_color?.color,
        }
      : {}

  return (
    <Button
      variant={'unstyled'}
      to={item?.button_link?.cached_url}
      minWidth={['85vw', 'auto']}
    >
      <Flex flexDirection="column" height={'100%'}>
        {!!item?.image && (
          <Flex px={0}>
            <FixedRatio
              ratio={[
                [1, 1],
                [416, 354],
              ]}
              width={'100%'}
            >
              <ToolkitImage
                src={item.image?.filename}
                alt={item.image?.alt}
                fluid={1000}
                width={'100%'}
                height={'100%'}
                fit="cover"
              />
            </FixedRatio>
          </Flex>
        )}
        <Flex
          backgroundColor={
            item?.background_color ? item?.background_color.color : 'auto'
          }
          gap={2}
          pt={2}
          px={3}
          pb={4}
          flexDirection={['column', 'row']}
          height={'100%'}
        >
          {!!item?.description_icon?.filename && (
            <Box>
              <ToolkitImage
                width={'64px'}
                src={item.description_icon?.filename}
                alt={item.description_icon?.alt}
                fluid={500}
                fit="contain"
              />
            </Box>
          )}
          <Flex flexDirection={'column'} gap={1}>
            {!!item?.title && (
              <Flex>
                <Heading
                  as="h2"
                  width="100%"
                  variant="sh2"
                  lineHeight={'115%'}
                  textAlign={!!item?.title_align ? item?.title_align : 'auto'}
                  color={item?.font_color ? item?.font_color.color : 'auto'}
                >
                  {item?.title}
                </Heading>
              </Flex>
            )}
            {!!item?.description && (
              <Flex>
                <Text
                  width="100%"
                  whiteSpace="pre-line"
                  fontSize={'20px'}
                  textAlign={
                    !!item?.description_align ? item?.description_align : 'auto'
                  }
                  color={item?.font_color ? item?.font_color.color : 'auto'}
                >
                  {item?.description}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
        {!!item?.button_text && (
          <Flex my={[5, 3]} marginBottom={carousel ? 5 : [5, 3]} width="100%">
            <Button
              variant="pink_small"
              my={0}
              to={item?.button_link?.cached_url}
              width={['100%', 'auto']}
              {...customButtonProps}
            >
              {item?.button_text}
            </Button>
          </Flex>
        )}
      </Flex>
    </Button>
  )
}

export const GridProductsHomeV2 = ({ content }: GridProductsHomeV2Props) => {
  return (
    <SectionWrapper>
      <Flex
        marginTop="0 !important"
        alignItems="center"
        width="100%"
        paddingBottom={[4, 8]}
        paddingTop={8}
        flexDirection="column"
        backgroundColor={
          content?.background_color ? content?.background_color.color : 'auto'
        }
      >
        {!!content?.title && (
          <Heading
            as="h3"
            variant="sh3"
            fontSize={['32px', '35px']}
            color={content?.font_color ? content?.font_color.color : 'black'}
            whiteSpace={'pre'}
            textAlign={'center'}
            mx={[2]}
          >
            {content?.title}
          </Heading>
        )}
        {!!content?.subtitle && (
          <Text
            mt={[1, 2]}
            mx={[2]}
            whiteSpace={['break-spaces', 'pre']}
            textAlign={'center'}
          >
            {content?.subtitle}
          </Text>
        )}
        {!!content?.items && (
          <Flex mt={[6]} px={[2]} width="100%">
            <Box display={'block'} width="100%">
              <Box
                width="100%"
                display={
                  content?.use_carousel_mobile
                    ? ['none', 'grid']
                    : [content?.stack_mobile ? 'grid' : 'flex', 'grid']
                }
                overflowY={['auto', 'auto', 'inherit']}
                gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
                style={{
                  columnGap: '16px',
                  rowGap: '32px',
                }}
              >
                {content?.items.map((el, index) => {
                  return <BlockItem key={el?._uid} item={el} carousel={false} />
                })}
              </Box>
              {content?.use_carousel_mobile && (
                <Box display={['block', 'none']} maxWidth="100%">
                  <Carousel
                    dynamicHeight={false}
                    showStatus={false}
                    showArrows={true}
                    showIndicators={false}
                    renderArrowNext={(onClickHandler, hasNext) =>
                      hasNext && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          style={{
                            right: 5,
                            position: 'absolute',
                            zIndex: 2,
                            top: 'calc(50% - 20px)',
                            width: 40,
                            height: 40,
                            cursor: 'pointer',
                            padding: '5px',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                          >
                            <path
                              d="M256 120.768L306.432 64 768 512l-461.568 448L256 903.232 659.072 512z"
                              fill="#000000"
                            />
                          </svg>
                        </button>
                      )
                    }
                    renderArrowPrev={(onClickHandler, hasPrev) =>
                      hasPrev && (
                        <button
                          type="button"
                          onClick={onClickHandler}
                          style={{
                            left: 5,
                            position: 'absolute',
                            zIndex: 2,
                            top: 'calc(50% - 20px)',
                            width: 40,
                            height: 40,
                            cursor: 'pointer',
                            padding: '5px',
                            background: 'white',
                            borderRadius: '50%',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                          >
                            <path
                              d="M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z"
                              fill="#000000"
                            />
                          </svg>
                        </button>
                      )
                    }
                  >
                    {content?.items.map((el, index) => {
                      return (
                        <BlockItem key={el?._uid} item={el} carousel={true} />
                      )
                    })}
                  </Carousel>
                </Box>
              )}
            </Box>
          </Flex>
        )}
      </Flex>
    </SectionWrapper>
  )
}
